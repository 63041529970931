// Removes cross site scripting
export function removeXSS(str: string | null): string | null {
    if (str) {
        return str.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, ' ')
    } else return null
}

// Replace string urls with enriched urls
export function setURLS(text: string) {
    const urlRegex =
        /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:;%_+.~#?&//=]*)/gi
    return text.replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank" rel="${
            url.includes(window.location.host) ? 'follow' : 'nofollow'
        }">${url}</a>`
    })
}

// returns default images
export const defaultImage = {
    coupon: 'https://media.megadescuentos.com/defaults/default-cupon.svg',
    offer: 'https://media.megadescuentos.com/defaults/default-oferta.svg',
    store: 'https://media.megadescuentos.com/defaults/default-stores.svg',
    category: 'https://media.megadescuentos.com/defaults/default-categories.svg',
    novelty: 'https://media.megadescuentos.com/defaults/novelties.svg',
    event: 'https://media.megadescuentos.com/events.svg',
    lottery: 'https://media.megadescuentos.com/defaults/sorteos-default.svg',
}

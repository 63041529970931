import { intervalToDuration, formatDuration } from 'date-fns'

import LANG from '../lang/es'

// <object> Return the current date
export function currentDate() {
    const state = new Date()
    const months = LANG.lib.date_functions.months
    return {
        month: months[state.getMonth()],
        year: state.getFullYear(),
        day: state.getDate(),
    }
}

// <string> Return the date difference and format it
export function getDateDifference(date) {
    const duration = intervalToDuration({
        start: new Date(date),
        end: new Date(),
    })

    const formatDistanceLocale = {
        xSeconds: '{{count}} ' + LANG.lib.date_functions.times.sec,
        xMinutes: '{{count}} ' + LANG.lib.date_functions.times.min,
        xHours: '{{count}} ' + LANG.lib.date_functions.times.hs,
        xDays: '{{count}} ' + LANG.lib.date_functions.times.day,
        xWeeks: '{{count}} ' + LANG.lib.date_functions.times.week,
        xMonths: '{{count}} ' + LANG.lib.date_functions.times.month,
        xYears: '{{count}} ' + LANG.lib.date_functions.times.year,
    }
    const shortEsLocale = {
        formatDistance: (token, count) => formatDistanceLocale[token].replace('{{count}}', count),
    }

    const units = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds']
    const nonzero = Object.entries(duration)
        .filter(([_, value]) => value)
        .map(([unit, _]) => unit)

    return formatDuration(duration, {
        format: units.filter((i) => new Set(nonzero).has(i)).slice(0, 1),
        delimiter: ', ',
        locale: shortEsLocale,
    })
}

// <string> Format date to dd/MM/yyyy | dd/MM/yyyy hh:mm | dd mm yyyy | dd mm yyyy hh:mm
// Join method could be ' ' or '/'
// ex: formatDate(date, ' ') -> '01 de 01 de 2020'
// ex: formatDate(date, '/') -> '01/01/2020'
// ex: formatDate(date, ' ', true) -> '01 de 01 de 2020 12:00'
// ex: formatDate(date, '/', true) -> '01/01/2020 12:00'
export function formatDate(date, join_method, hours = false) {
    try {
        const method =
            join_method === '/'
                ? {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      /* timeZone: 'Europe/Madrid', */
                  }
                : {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      /*  timeZone: 'Europe/Madrid', */
                  }
        const createDate = new Date(date)
        if (hours) {
            return new Intl.DateTimeFormat(LANG.lib.date_functions.datetimeFormat, {
                hour: 'numeric',
                minute: 'numeric',
                /* timeZone: 'Europe/Madrid', */
            }).format(createDate)
        }
        return new Intl.DateTimeFormat(LANG.lib.date_functions.datetimeFormat, method)
            .format(createDate)
            .split(' ')
            .join(join_method)
    } catch (error) {
        return null
    }
}

export function formatDatAndMonth(date) {
    try {
        const options = {
            timeZone: LANG.lib.date_functions.timezone,
        }

        const createDate = new Date(date)

        return new Intl.DateTimeFormat(LANG.lib.date_functions.datetimeFormat, options).format(createDate)
    } catch (error) {
        return null
    }
}


import Vue, { PropType } from 'vue'

export default Vue.extend({
    name: 'MicroHeatCounterVote',
    props: {
        fire: {
            type: Boolean,
            default: true,
        },
        scale: String as PropType<'small' | undefined>,
        voted: {
            type: [Boolean, Number],
            default: false,
        },
        count: {
            type: Number,
            default: 0,
            required: true,
        },
        voteableId: {
            type: Number,
        },
        voteableType: {
            type: String,
        },
        expire: {
            type: Boolean,
            default: false,
        },
        showExpire: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            newCount: null as null | number,
            alreadyVoted: this.voted as boolean | number,
            voting: false as boolean | string,
        }
    },
    computed: {
        leftColor(): 'gray' | 'blue' {
            return typeof this.alreadyVoted === 'number' ? 'gray' : 'blue'
        },
        rightColor(): 'red' | 'gray' {
            return typeof this.alreadyVoted === 'number' ? 'gray' : 'red'
        },
        fireColor(): 'red' | 'orange' | 'gray' | 'blue' {
            return (this.newCount || this.count) > 100
                ? 'red'
                : (this.newCount || this.count) > 50
                ? 'orange'
                : (this.newCount || this.count) > 0
                ? 'gray'
                : 'blue'
        },
        textColor(): 'text-red-500' | 'text-orange-500' | 'text-gray-800' | 'text-sky-600' {
            return (this.newCount || this.count) > 100
                ? 'text-red-500'
                : (this.newCount || this.count) > 50
                ? 'text-orange-500'
                : (this.newCount || this.count) > 0
                ? 'text-gray-800'
                : 'text-sky-600'
        },
    },
    methods: {
        async makeVote(userVoted: number) {
            this.voting = userVoted ? 'right' : 'left'
            try {
                if (this.voteableId && this.voteableType) {
                    const voteResult = await this.$api.create.postVote({
                        voteable_type: this.voteableType as 'coupon' | 'offer',
                        voteable_id: this.voteableId,
                        type: userVoted,
                    })

                    if (voteResult.feedback === 'data_success') {
                        this.alreadyVoted = userVoted

                        this.newCount = this.count + (userVoted ? 1 : -1)
                    }
                }
            } catch (e: any) {}
            this.voting = false
        },
    },
})

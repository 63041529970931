
import Vue, { PropType } from 'vue'
import { isToday, isBefore, isTomorrow, parseISO, differenceInMinutes } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { getDateDifference } from '~/libs/date-functions'
import { numberFormattedWithCommas } from '@/libs/string-functions'
import { Models } from '@/types/models'
import { getDictionary } from '@/libs/variant-dict'
import { defaultImage } from '~/libs/useful-functions'

export default Vue.extend({
    name: 'CardMainGlobal',
    props: {
        info: {
            type: Object as PropType<Models.Discount>,
            required: true,
        },
        inStoreRoute: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            saved: !!this.info.saved,
            numberFormattedWithCommas,
            getDateDifference,
            defaultImage,
        }
    },
    computed: {
        isAuth(): boolean {
            return this.$store.state.auth.isAuth
        },
        isExpired(): boolean {
            return this.info.expired_at ? isBefore(parseISO(this.info.expired_at), new Date()) : false
        },
        addExpiredGuard(): boolean {
            return this.info.expired_at
                ? isBefore(
                      parseISO(this.info.expired_at),
                      new Date(new Date().setMonth(new Date().getMonth() - 2))
                  )
                : false
        },
        isProgrammed(): { text: string; status: boolean } {
            if (!!this.info.start_at && parseISO(this.info.start_at) > new Date()) {
                return { text: this.$util.lang.components.cardMainGlobal.programmed, status: true }
            }
            // return { text: 'Programado', status: true }
            return { text: '', status: false }
        },
        isOffer(): boolean {
            return this.info.type === 'offer'
        },
        isCoupon(): boolean {
            return this.info.type === 'coupon'
        },
        isAdmin(): boolean {
            return this.info.is_admin
        },
        variant() {
            return getDictionary(this.info.type)
        },
        buttonMode(): string {
            if (this.isOffer) {
                if (this.info.has_url) {
                    if (this.info.code) {
                        return 'offer-code'
                    } else {
                        return 'offer'
                    }
                } else {
                    return 'no-link-offer'
                }
            } else if (this.isCoupon) {
                if (this.info.has_url) {
                    return 'coupon'
                } else {
                    return 'no-link-coupon'
                }
            }
            return ''
        },
        showCode(): boolean {
            return this.buttonMode === 'no-link-coupon' ? this.$route.name === 'cupones-slug' : true
        },
        content() {
            return this.info.description?.replace(/<[^>]+>/g, '')
        },
        refreshedAt30MinsAgo(): number {
            const date = this.info.updated_at && parseISO(this.info.updated_at)
            const difference = !!date && differenceInMinutes(new Date(), date)

            return (difference && difference > 0 && difference < 30 && difference) || 0
        },
        endingDate(): string {
            const date = this.info.expired_at && parseISO(this.info.expired_at)

            return date ? formatInTimeZone(date, 'America/Mexico_City', 'dd/MM/yyyy') : ''
        },
        endToday(): boolean {
            return !!this.info.expired_at && isToday(parseISO(this.info.expired_at))
        },
        endTomorrow(): boolean {
            return !!this.info.expired_at && isTomorrow(parseISO(this.info.expired_at))
        },
        endInLessThanTwoDays(): boolean {
            return this.endToday || this.endTomorrow
        },
        startingDate(): boolean | string {
            const date = this.info.start_at && parseISO(this.info.start_at)

            return date && date > new Date()
                ? formatInTimeZone(date, 'America/Mexico_City', 'dd/MM/yyyy HH:mm')
                : false
        },
        shippingPrice(): string | boolean {
            return this.isFreeShipping
                ? 'Envío gratis'
                : !!this.info.shipping_price &&
                      this.info.shipping_price !== '0' &&
                      this.$util.lang.symbol + this.info.shipping_price
        },
        isFreeShipping(): boolean {
            return this.info.discount_type === 'envío gratis' || !!this.info.shipping_free
        },
        showOldPrice(): boolean {
            return (
                this.isOffer && !!this.info.old_price && !!this.info.new_price && this.info.new_price !== '0'
            )
        },
        showNewPrice(): boolean {
            return (
                (this.isOffer && !!this.info.new_price) ||
                (this.isCoupon &&
                    (((this.info.discount_type === 'precio' || this.info.discount_type === 'porcentaje') &&
                        this.info.discount_value !== '0') ||
                        this.info.discount_type === 'envío gratis'))
            )
        },
        newOfferPrice(): string {
            return this.info.new_price === '0'
                ? this.$util.lang.components.cardMainGlobal.free
                : this.$util.lang.symbol + this.info.new_price
        },
        newCouponPrice(): string {
            return this.info.discount_type === 'envío gratis'
                ? this.$util.lang.components.cardMainGlobal.free_ship
                : this.info.discount_type === 'precio'
                ? this.$util.lang.symbol + this.info.discount_value + ' OFF'
                : this.info.discount_value === '100'
                ? this.$util.lang.components.cardMainGlobal.free
                : this.info.discount_value + '% OFF'
        },
        newPrice(): string {
            return (this.isOffer && this.newOfferPrice) || (this.isCoupon && this.newCouponPrice) || ''
        },
        showPercent(): boolean {
            return (
                !!this.info.old_price &&
                !!this.info.new_price &&
                !!this.info.discount_percent &&
                this.info.new_price !== '0'
            )
        },
        showPrices(): boolean {
            return this.showNewPrice || !!this.startingDate
        },
        createdAt(): Date {
            return parseISO(this.info.created_at)
        },
        updatedAt(): Date | null {
            return this.info.updated_at ? parseISO(this.info.updated_at) : null
        },
        areTimestampsEqual(): boolean {
            return (
                !!this.createdAt &&
                !!this.updatedAt &&
                getDateDifference(this.createdAt) === getDateDifference(this.updatedAt)
            )
        },
    },
    watch: {
        info: {
            deep: true,
            handler(info: Models.Discount) {
                this.saved = !!info.saved
            },
        },
    },
    methods: {
        openCoupon() {
            if (this.isAuth || !this.addExpiredGuard) {
                if (this.info.is_admin || this.info.without_detail) {
                    this.$openCoupon(
                        this.info.slug,
                        this.info.without_detail ? '' : this.info.store?.slug || ''
                    )
                } else {
                    this.$openCoupon(this.info.slug)
                }
            } else {
                this.navigationGuard()
            }
        },
        navigationGuard() {
            this.$store.commit('setModal', {
                name: 'ModalAuthRegister',
            })
        },
        openOffer() {
            if (!this.addExpiredGuard) {
                this.$openOffer(this.info.id)
            } else {
                this.navigationGuard()
            }
        },
        async savePost() {
            try {
                const saveResult = await this.$api.create.savePost({
                    saveable_id: this.info.id,
                    saveable_type: this.info.type,
                    type: this.saved ? 0 : 1,
                })

                if (saveResult?.feedback === 'data_success') {
                    this.saved = !this.saved
                    this.$root.$emit('notification', {
                        duration: 5000,
                        dismissButtonText: this.$util.lang.components.cardMainGlobal.agreement,
                        text: `${
                            this.isOffer
                                ? this.$util.lang.components.cardMainGlobal.offer
                                : this.$util.lang.components.cardMainGlobal.coupon
                        } <span style="font-weight:600">${this.$util.textShortener(
                            this.info.title,
                            this.$bp.mb ? 30 : this.$bp.xs || this.$bp.sm ? 60 : 90
                        )}</span> ${
                            this.saved
                                ? this.isOffer
                                    ? this.$util.lang.components.cardMainGlobal.saved_a
                                    : this.$util.lang.components.cardMainGlobal.saved
                                : this.isOffer
                                ? this.$util.lang.components.cardMainGlobal.deleted_a
                                : this.$util.lang.components.cardMainGlobal.deleted
                        }`,
                        type: 'success',
                    })
                } else if (saveResult?.feedback === 'error_fields_validation') {
                    this.$root.$emit('notification', {
                        duration: 5000,
                        dismissButtonText: this.$util.lang.components.cardMainGlobal.agreement,
                        text:
                            saveResult.errors.type?.[0] ||
                            saveResult.errors.saveable_type?.[0] ||
                            saveResult.errors.id?.[0] ||
                            saveResult.errors.saveable_id?.[0] ||
                            `${this.$util.lang.components.cardMainGlobal.error_on} ${
                                this.saved
                                    ? this.$util.lang.components.cardMainGlobal.delete
                                    : this.$util.lang.components.cardMainGlobal.save
                            }, ${this.$util.lang.components.cardMainGlobal.something_happens} ${
                                this.isOffer
                                    ? this.$util.lang.components.cardMainGlobal.this_offer
                                    : this.$util.lang.components.cardMainGlobal.this_coupon
                            }.`,
                        type: 'error',
                    })
                } else if (
                    this.$store.getters['auth/isAuth'] &&
                    this.$store.state.user.profile?.accepted_conditions_at &&
                    this.$store.state.user.profile?.verified_email
                ) {
                    this.$root.$emit('notification', {
                        duration: 5000,
                        dismissButtonText: this.$util.lang.components.cardMainGlobal.agreement,
                        text: this.$util.lang.components.cardMainGlobal.error_on_save,
                        type: 'error',
                    })
                }
            } catch (e) {}
        },
    },
})

import LANG from '../lang/es'

// Get dictionary from variant (offer, coupon, lottery)
export const getDictionary = (
    variant: 'offer' | 'coupon' | 'lottery' | 'chat' | 'forum'
): {
    variant: 'coupon' | 'offer' | 'lottery' | 'chat' | 'forum'
    path: string
    wordPlural: string
    wordSingular: string
    wordSingularOne: string
    wordPlain?: string
} => {
    switch (variant) {
        case 'offer':
            return {
                variant: 'offer',
                path: `/${LANG.routes.offer}`,
                wordPlural: LANG.lib.variant_dict.offer.plural,
                wordSingular: LANG.lib.variant_dict.offer.singular,
                wordSingularOne: LANG.lib.variant_dict.offer.singular_one,
                wordPlain: LANG.lib.variant_dict.offer.plain,
            }
        case 'coupon':
            return {
                variant: 'coupon',
                path: `/${LANG.routes.coupons}`,
                wordPlural: LANG.lib.variant_dict.coupon.plural,
                wordSingular: LANG.lib.variant_dict.coupon.singular,
                wordSingularOne: LANG.lib.variant_dict.coupon.singular_one,
                wordPlain: LANG.lib.variant_dict.coupon.plain,
            }
        case 'lottery':
            return {
                variant: 'lottery',
                path: `/${LANG.routes.lotteries}`,
                wordPlural: LANG.lib.variant_dict.lottery.plural,
                wordSingular: LANG.lib.variant_dict.lottery.singular,
                wordSingularOne: LANG.lib.variant_dict.lottery.singular_one,
            }
        case 'chat':
            return {
                variant: 'chat',
                path: `/${LANG.routes.forum}`,
                wordPlural: LANG.lib.variant_dict.chat.plural,
                wordSingular: LANG.lib.variant_dict.chat.singular,
                wordSingularOne: LANG.lib.variant_dict.chat.singular_one,
            }
        case 'forum':
            return {
                variant: 'forum',
                path: `/${LANG.routes.forum}`,
                wordPlural: LANG.lib.variant_dict.chat.plural,
                wordSingular: LANG.lib.variant_dict.chat.singular,
                wordSingularOne: LANG.lib.variant_dict.chat.singular_one,
            }
    }
}

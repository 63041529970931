import { format } from 'date-fns'
import { es as locale } from 'date-fns/locale'

// Receive a number and return a string with ',' every 3 digits
export const numberFormattedWithCommas = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function getExpireMessage(date: string) {
    const expireMessages = [
        'Finaliza HOY',
        'Finaliza MAÑANA',
        'Finaliza en [days] dias',
        'Finaliza en 1 semana',
        'Finaliza el [date]',
    ]

    const now = new Date().getTime()
    const time = new Date(date).getTime()
    const days = Math.ceil((time + 2 - now) / (1000 * 60 * 60 * 24))

    let message = expireMessages[days] || ''
    let isDanger = false

    if (days > 1 && days < 7) message = expireMessages[2].replace('[days]', String(days))

    if (days > 7) {
        const expire = format(time, 'dd/MM/yyyy', { locale }) + ' a las ' + format(time, 'hh:ss') + 'h'
        message = expireMessages[4].replace('[date]', expire)
    }

    if (days === 7) message = expireMessages[3]
    if (days <= 3) isDanger = true

    return { message, isDanger }
}

export function createFormatDate(date: string, hours = false) {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'America/New_York',
    }

    if (hours) {
        options.hour = 'numeric'
        options.minute = 'numeric'
        options.hour12 = false
    }

    return new Intl.DateTimeFormat('en-US', options).format(new Date(date))
}

export function joinDateToString(dateFormat: { date: string; time: string }): string {
    const { date, time } = dateFormat
    if (!date) return ''
    const dateString = createFormatDate(date) + ' ' + time
    return new Date(dateString).toISOString()
}
